<template>
  <div class="animation-number-cover">
    <span v-if="showAnimation" class="animation-number">{{ $formatNumberWithCommas(displayNumber, separator) }}</span>
    <span v-else class="animation-number">
      {{ $formatNumberWithCommas(number, separator) }}
    </span>
    <CommonBaseImg v-if="showCoin" src="/assets/images/icon/coin-k.svg" class="icon-coin" alt="icon-coin" />
  </div>
</template>

<script setup lang="ts">
import CommonBaseImg from '~/components/common/base-img.vue'
const props = withDefaults(
  defineProps<{
    number: number
    fromNumber?: number
    showCoin?: boolean
    showAnimation?: boolean
    separator?: string
  }>(),
  {
    number: 0,
    fromNumber: 0,
    showCoin: false,
    showAnimation: true,
    separator: '.'
  }
)

const displayNumber = ref<number>(props.fromNumber ? props.fromNumber : 0)
const interval = ref<number | undefined>(undefined)

function $formatNumberWithCommas(number: number, separator: string = '.') {
  const parts = number.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, separator)
  return parts.join('.')
}

const watchNumber = () => {
  clearInterval(interval.value)

  if (props.number === displayNumber.value) {
    return
  }
  if (typeof window !== 'undefined') {
    interval.value = window.setInterval(() => {
      if (displayNumber.value !== props.number) {
        let change = (props.number - displayNumber.value) / 10
        change = change >= 0 ? Math.ceil(change) : Math.floor(change)
        displayNumber.value = displayNumber.value + change
      }
    }, 50)
  }
}
watchNumber()

watch(
  () => props.number,
  () => {
    watchNumber()
  }
)
</script>

<style lang="scss" scoped src="~/assets/scss/components/common/animate-number.scss"></style>
